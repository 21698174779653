import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 로그인 필수 여부
// const requiresAuthentication = (to, from, next) => {
//     if (Vue.prototype.userInfo("userId") != null) {
//         return next();
//     } else {
//         if (confirm("사용자 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?")) {
//             if (from.path == "/user/login" && from.fullPath == to.fullPath) {
//                 return false;
//             } else {
//                 router.push({
//                     path: "/user/login",
//                     query: {returnUrl: to.fullPath},
//                     replace: true,
//                 });
//             }
//         } else {
//             return false;
//         }
//     }
// };

Vue.prototype.authRoutes = [
    {
        path: "/system",
        roleCd: "ROLE_ADMIN"
    },
    {
        path: "/manage",
        roleCd: "ROLE_ADMIN"
    }
];

// NavigationDuplicated 대응
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
            throw err;
        }
    });
};
// 메인홈페이지
const routes = [{
        path: "/user",
        component: () =>
            import ("@/components/user/UserLayout.vue"),
        children: [{
            path: "/",
            redirect: "/user/info",
        },
            {
                path: "info",
                component: () =>
                    import ("@/components/user/UserInfo.vue")
            },
            {
                name: "login", //사용자로그인
                path: "login",
                component: () =>
                    import ("@/components/user/UserLogin.vue"),
            },
            {
                name: "managelogin", //관리자로그인
                path: "managelogin",
                component: () =>
                    import ("@/components/user/ManageLogin.vue"),
            },
            {
                name: "manageloginsuperuser", //관리자로그인 - Super User
                path: "manageloginsuperuser",
                component: () =>
                    import ("@/components/user/ManageLoginSuperUser.vue"),
            },
            {
                name: "membershipagree", //회원가입동의서
                path: "membershipagree",
                component: () =>
                    import ("@/components/user/MebershipAgree.vue"),
            },
            {
                name: "joinmembership", //회원가입
                path: "joinmembership",
                component: () =>
                    import ("@/components/user/JoinMembership.vue"),
            },
            {
                name: "findid", //아이디찾기
                path: "findid",
                component: () =>
                    import ("@/components/user/FindId.vue"),
            },
            {
                name: "findpassword", //비밀번호찾기
                path: "findpassword",
                component: () =>
                    import ("@/components/user/FindPassword.vue"),
            },
            {
                name: "emailvalid", // 이메일 인증
                path: "emailvalid",
                component: () =>
                    import ("@/components/user/EmailValid.vue"),
            },
        ],
    },
    {
        path: "/applicant",
        component: () =>
            import ("@/components/applicant/ApplicantLayout.vue"),
        children: [{
            path: "/",
            redirect: "/applicant/applicant",
        },
            {
                path: "applicant", // 원서접수
                component: () =>
                    import ("@/components/applicant/applicant/ApplicantForm.vue"),
            },
            // {
            //     path: "resultdocumentveiw", //서류심사결과확인
            //     component: () =>
            //         import ("@/components/applicant/document/ResultDocumentVeiw.vue"),
            // },
            {
                path: "lastresultview", //최종합격자발표
                component: () =>
                    import ("@/components/applicant/result/LastResultView.vue"),
            },
            {
                path: "register", // 인턴십 등록
                component: () =>
                    import ("@/components/applicant/register/RegisterView.vue"),
            },
            {
                path: "rollbook", //인턴십출석부제출
                component: () =>
                    import ("@/components/applicant/rollbook/RollBook.vue"),
            },
            {
                path: "certificate", //수료증 확인
                component: () =>
                    import ("@/components/applicant/certificate/Certificate.vue"),
            },
            // {
            //     path: "satisfactionsurvey", //인턴십만족도조사
            //     component: () =>
            //         import ("@/components/applicant/statistics/SatisfactionSurvey.vue"),
            // },
            {
                path: "userinfo", //비밀번호 수정
                component: () =>
                    import ("@/components/applicant/applicant/UserInfoEdit.vue"),
            },
        ],
    },
    {
        path: "/",
        redirect: "/user/login",
    },
    {
        path: "/common/notfound",
        component: () =>
            import ("@/components/common/NotFound"),
    },
    {
        path: "*",
        redirect: "/common/notfound",
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) =>
{
    let roleCd = sessionStorage.getItem("roleCd");

    console.log("roleCd: " + roleCd)
    let routeNext = true;

    for(let path of Vue.prototype.authRoutes)
    {
        if(to.path.indexOf(path.path) >= 0)
        {
            routeNext = false;
            if((roleCd != null && roleCd.includes(path.roleCd)) || path.roleCd.includes('ROLE_ANONYMOUS'))
            {
                routeNext = true;
                break;
            }
        }
    }

    if(routeNext)
    {
        next();
    }
    else{
        if(roleCd != null)
        {
            alert("현재 로그인한 사용자는 `" + to.path + "`에 접근할 권한이 없습니다.");
            router.push("/");
        }
        else if(confirm("사용자의 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?")) {
            router.push({
                path: to.fullPath.indexOf("/manage") >= 0 ? "/user/managelogin" : "/user/login",
                query: {returnUrl: to.fullPath},
                replace: true,
            });
        }
        else{
            router.push("/");
        }
    }
});

export default router;