import Vue from "vue";
import moment from "moment";
import axios from "axios";

export default "utils"

//Utils
Vue.prototype.utils = {
    isNull: function(args) {
        if (args === undefined || args == null || args == "") {
            return true;
        } else {
            return false;
        }
    },
    isEmpty: function(args) {
        return this.isNull(args);
    },
    getNow(){
        const current = new Date();
        const date = current.getFullYear()+'-'+(current.getMonth()+1).toString().padStart(2,'0')+'-'+current.getDate();
        return date;
    },
    formatDate(value) {
        return value ? moment(value).format("YYYY-MM-DD") : value;
    },
    formatTime(value) {
        var result = value;
        if(value){
            if(value.length == 6){
                result = value.slice(0, 2) + ":" + value.slice(2, 4) + ":" + value.slice(4, 6);
            }else if(value.length == 4){
                result = value.slice(0, 2) + ":" + value.slice(2, 4);
            }
        }
        return result;
    },
    addComma(value) {
        let strVal = String(value);
        let length = strVal.length;
        let result = strVal;

        if(length > 3) {
            let count = parseInt(length / 3);
            result = strVal.slice(0, length - count*3);

            for(let i = 0; i < count; i++){
                let start = length - (count - i)*3;
                let end = length - (count - i - 1)*3;
                result += ',' + strVal.slice(start, end);
            }
        }

        return result.startsWith(',') ? result.slice(1) : result;
    },
    cutMsg(len, _msg)
    {
        var checkText = _msg;
        var checkByte = 0;
        var nText = "";
        
        for(var i = checkText.length; i>=0; i--)
        {
            checkByte = this.getByte(checkText.substr(0,i));

            if(parseInt(checkByte) <= parseInt(len))
            {
                nText = checkText.substr(0,i);
                break;	
            }
        }
        return nText;

    },
    getByte(_msg){
        if(_msg == undefined) return;
        return ~-encodeURI(_msg).split(/%..|./).length;
    },
    getByteLength(string){
        if(!string){
            return 0;
        }

        let byte = 0;
        let sLen = string.length;

        for(let idx = 0; idx < sLen; idx++){
            byte += (string.charCodeAt(idx)>>7 ? 2 : 1);
        }

        return byte;
    },
    getToday(){
        var date = new Date();
        var year = date.getFullYear();
        var month = ("0" + (1 + date.getMonth())).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
    
        return year + month + day;
    },
    tableToExcel(){

    },
    /** script관련 */
    isExScLoaded : false,
    excelScripts : [
        {
            name : 'excelJS',
            src : 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.5/xlsx.full.min.js',
        },
        {
            name : 'fileSaverJS',
            src : 'https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/1.3.8/FileSaver.min.js',
        },
    ],
    /** 엑셀관련 */
    scriptsLoad(){
        // 한번만 로드
        if(this.isExScLoaded){
            return;
        }

        this.excelScripts.forEach((scriptInfo) => {
            let scriptTag = document.createElement("script");
            scriptTag.setAttribute('src', scriptInfo.src);
            document.head.appendChild(scriptTag);
        });

        this.isExScLoaded = true;
    },
    /** 엑셀관련 */
    excelDownload(table, sheetNm, fileNm){
        
        // table 파라미터 확인
        if(!table || table.tagName.toLowerCase() != "table"){
            alert("출력할 테이블이 존재하지 않습니다.");
            return;
        }
        
        // 엑셀 js로드
        this.scriptsLoad();

        let sheetName = typeof sheetNm == 'string' ? sheetNm : "임시";
        let fileName = typeof fileNm == 'string' ? fileNm : "임시";
        
        const XLSX = window.XLSX;

        // 워크북 생성
        var wb = XLSX.utils.book_new();

        // 테이블 기반 워크시트 생성
        var worksheet = XLSX.utils.table_to_sheet(table);

        // 생성된 워크시트를 워크북에 추가
        XLSX.utils.book_append_sheet(wb, worksheet, sheetName);

        // 엑셀 파일 만들기 
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        
        // 버퍼생성 및 내용저장
        let buf = new ArrayBuffer(wbout.length);
        let view = new Uint8Array(buf);
        for (let i=0; i<wbout.length; i++){
            view[i] = wbout.charCodeAt(i) & 0xFF;
        } 

        // 엑셀 다운로드
        window.saveAs(new Blob([buf],{type:"application/octet-stream"}), fileName + '.xlsx');
    },
    /** Local Change Method */
    toggleLocale(obj, locale) {
        localStorage.setItem("lang", locale);
        obj.locale = locale;
    },
    downloadTemplate(filename){
        const formData = new FormData();
        formData.append("filename", filename);

        axios
            .post("/common/file/downloadTemplate.do", formData, { responseType: "blob" })
            .then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] }),
                );
                const link = document.createElement("a");
                link.href = url;
                console.log(url);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            });
    },
};
