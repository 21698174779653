import Vue from "vue";
import axios from "axios";
import router from "./router";
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

export default "auth";

// const env = process.env.NODE_ENV
//Config
Vue.prototype.wsurl = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/ws";
Vue.prototype.stompClient;

Vue.prototype.userInfo = (key) => {
    return sessionStorage.getItem(key);
};

Vue.prototype.auth = {
    login: async (userId, password, returnUrl, loginType, normalId = "") => {
        return await axios
            .post("/common/auth/login.do?loginType=" + loginType, {
                userId: userId,
                userPassword: password,
                loginType: loginType,
                normalId: normalId
            })
            .then((response) => {
                sessionStorage.clear();
                let responseData = JSON.parse(response.data.replaceAll("&#34;", "\""));

                let result = responseData.message;
                if (result["userId"] != "") {
                    Object.keys(result).forEach((key) => {
                        // console.log(key + ":" + result[key]);
                        if (key != "" && key != "pathList") {
                            sessionStorage.setItem(key, result[key]);
                        }
                    });
                }

                let roleCd = sessionStorage.getItem("roleCd");
                if(loginType == "MANAGER" && roleCd != null && roleCd.indexOf("ROLE_ADMIN") == -1)
                {
                    returnUrl = "/applicant";
                }

                if (responseData.status == 200) {
                    Vue.prototype.auth.connect();
                    router.replace(returnUrl);
                }
                return true;
            })
            .catch((error) => {
                console.log(error);
                let responseData = "";
                if((error.response.data == null || error.response.data == undefined) && error.message != null && error.message != undefined)
                {
                    responseData = error.message;
                }
                if(error.response.data != null && error.response.data != undefined)
                {
                    responseData = JSON.parse(error.response.data.replaceAll("&#34;", "\""));
                }
                else if(error.response.error != null && error.response.error != undefined)
                {
                    responseData = JSON.parse(error.response.error.replaceAll("&#34;", "\""));
                }

                var errorMessage = responseData.message;

                if(errorMessage != null && errorMessage.lastIndexOf(" ") >= 0)
                {
                    errorMessage = errorMessage.substr(errorMessage.lastIndexOf(" ") + 1)
                }
                
                let locale = localStorage.getItem("lang");
                if(errorMessage == "password"){
                    alert(locale == "en" ? "Please check your ID or password." : "아이디 또는 비밀번호를 확인하세요.");
                }
                else if(errorMessage == "email"){
                    alert(locale == "en" ? "This email is not authorized." : "인증되지 않은 이메일입니다.");
                    router.replace(`/user/emailValid?emailAddress=${userId}`);
                }
                else if(errorMessage == "ip"){
                    alert(locale == "en" ? "Unregistered internal IP." : "등록되지 않은 내부 IP입니다.");
                }
                else if(errorMessage == "loginType"){
                    alert(locale == "en" ? "Invalid login type." : "유효하지 않은 로그인 타입입니다." );
                }

                console.log("[" + responseData.status + "]" + responseData.message);

                return false;
            });
    },
    setReturnUrl : (returnUrl, loginType) => {

        if (returnUrl == "/user/login" || returnUrl == "/user/managelogin" || returnUrl == "/user/manageloginsuperuser") {
            returnUrl = "/";
        }
        if (returnUrl === undefined) {
            if(loginType == "USER")
            {
                returnUrl = "/applicant";
            }
            else if(loginType == "MANAGER")
            {
                returnUrl = "/manage";
            }
        }

        return returnUrl;
    },
    info: async () => {
        return await axios
            .post("/common/auth/info.do")
            .then((response) => {
                console.log(response.data);

                let result = response.data;
                if (result["userId"] != "") {
                    Object.keys(result).forEach((key) => {
                        console.log(key + ":" + result[key]);
                        if (key != "" && key != "pathList") {
                            sessionStorage.setItem(key, result[key]);
                        }
                    });
                }

                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    logout: async () => {
        return await axios       
            .post("/common/auth/logout.do")
            .then((response) => {
                let responseData = JSON.parse(response.data.replaceAll("&#34;", "\""));

                if (responseData.status == 200) {
                    sessionStorage.clear();
                    window.open("/", "_self");
                    return true;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
    },
    connect: () => {
        //console.log(sessionStorage);
        if (sessionStorage.getItem("userId") == null || sessionStorage.getItem("sessionId") == null) {
            return;
        }
        // console.log("connectSocket()");
        const serverURL = Vue.prototype.wsurl;
        let socket = new SockJS(serverURL);
        Vue.prototype.stompClient = Stomp.over(socket);
        // 소켓 로그 안나오도록 조치
        Vue.prototype.stompClient.debug = () => {
        };
        // console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`);
        Vue.prototype.stompClient.connect({},
            // eslint-disable-next-line no-unused-vars
            (frame) => {
                //console.log("소켓 연결 성공", frame);
                Vue.prototype.stompClient.subscribe("/ws/send" + sessionStorage.getItem("sessionId"), (res) => {
                    let result = JSON.parse(res.body);
                    if (result.command == 'timeOut')
                    {
                        sessionStorage.setItem("remainTime", "0");
                    }

                    if (result.command == "getRemainTime" && result.remainTime ^ 1 > 0) {
                        sessionStorage.setItem("remainTime", result.remainTime ^ 1);
                        // console.log("Vue.prototype.loopCountTime : " + Vue.prototype.loopCountTime);
                        if (Vue.prototype.loopCountTime == undefined) {
                            // console.log("remainTimeCounter 작동!!!")
                            Vue.prototype.loopCountTime = setInterval(() => {
                                Vue.prototype.auth.remainTimeCounter()
                            }, 1000);
                        }
                    }
                });
            },
            (error) => {
                console.log(error);
            }
        );

        Vue.prototype.loopRemainTime = setInterval(() => {
            Vue.prototype.auth.getRemainTime()
        }, 1000)
    },
    remainTimeCounter: () => {
        let remainTime = sessionStorage.getItem("remainTime") - 1;
        sessionStorage.setItem("remainTime", remainTime);

        if (remainTime <= 0) {
            clearInterval(Vue.prototype.loopCountTime);
            /** clearInterval 이후에도 loopCountTime 미초기화로 인해
                connect 메서드의 Vue.prototype.loopCountTime = setInterval 이 작동 안되는 현상 방지 */
            Vue.prototype.loopCountTime = undefined;
        }
    },
    getRemainTime: () => {
        // console.log("getRemainTime / " + Vue.prototype.stompClient.connected)
        if (Vue.prototype.stompClient && Vue.prototype.stompClient.connected) {
            clearInterval(Vue.prototype.loopRemainTime)

            const msg = {
                userId: sessionStorage.getItem("userId"),
                sessionId: sessionStorage.getItem("sessionId"),
                command: "getRemainTime"
            };
            Vue.prototype.stompClient.send("/ws/receive", JSON.stringify(msg), {});
        }
    },
    isButtonRole: (menuNo) => {
        return axios
                .post("/common/auth/authButtonList.do", {menuNo : menuNo})
                .then((response) => {
                console.log(response)
                return response.data.result;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
    },
};