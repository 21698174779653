import Vue from "vue";

export default "internCommon";

// 인턴십 관련 공통
Vue.prototype.internCommon = {

    /** DISABLED 처리 */
    changeElements(parentsThis) {

        const targetBox = parentsThis.$refs.targetBox;

        if (!targetBox) {
            return;
        }

        const inputs = Array.from(targetBox.querySelectorAll("input")).filter((input) => {
            const classList = Array.from(input.classList);
            return !classList.includes("exclude");
        });

        const selects = Array.from(targetBox.querySelectorAll("select")).filter((input) => {
            const classList = Array.from(input.classList);
            return !classList.includes("exclude");
        });

        const radios = Array.from(targetBox.querySelectorAll("radio")).filter((input) => {
            const classList = Array.from(input.classList);
            return !classList.includes("exclude");
        });

        const buttons = Array.from(targetBox.querySelectorAll("button")).filter((input) => {
            const classList = Array.from(input.classList);
            return !classList.includes("exclude");
        });

        const textareas = Array.from(targetBox.querySelectorAll("textarea")).filter((input) => {
            const classList = Array.from(input.classList);
            return !classList.includes("exclude");
        });

        inputs.forEach((input) => {
            input.disabled = true;
        });

        selects.forEach((select) => {
            select.disabled = true;
        });

        radios.forEach((radio) => {
            radio.disabled = true;
        });

        buttons.forEach((button) => {

            button.style.display = "none";

        });

        textareas.forEach((textarea) => {
            textarea.disabled = true;
        });

    },

};